import React, { useState } from "react";
import Image from "react-image-enlarger";

export default function ImageEnlarger({ ...rest }) {
  const [zoomed, setZoomed] = useState(false);

  return (
    <Image
      {...rest}
      onClick={() => setZoomed(true)}
      onRequestClose={() => setZoomed(false)}
      zoomed={zoomed}
    />
  );
}
